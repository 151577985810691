<view-header [backRoute]="{label: 'E-pood', route: '/'}"></view-header>

<view-content bgColor="var(--app-bg-secondary)">
  <div class="flex flex-grow flex-col gap-5x">
    <div class="flex flex-col gap-5x">
      <h1>Täname ostu eest!</h1>

      <div class="flex flex-col font-text text-sm font-normal text-grey-100">
        <span>
          Tellimus on edastatud tarnijatele ja hetkel ootab kinnitust.
        </span>

        <span>
          Kui tarnijad on tellimuse kinnitanud, saad sellest teade kätte e-maili
          <a class="font-medium text-tangerine" href="mailto:martin@ails.ee">
            martin&#64;alis.ee
          </a>
        </span>
      </div>

      <div class="flex flex-col gap-5x py-5x">
        <button
          appButton
          color="primary"
          size="big"
          label="action.continue_shopping"
          routerLink="/"
        ></button>
        <button
          appButton
          color="transparent"
          size="big"
          label="action.view_your_orders"
          routerLink="/orders"
        ></button>
      </div>
    </div>
    <!-- [repeat(auto-fit,minmax(21rem,1fr))] -->
    <div class="grid-table !grid !grid-cols-1 gap-5x">
      <div class="card !flex-none p-6x">
        <div class="flex flex-col gap-5x">
          <h4>{{'common.label.delivery_location' | translate}}</h4>

          <icon-value-field icon="monochromeAddress">
            <span text1> Harkujärve tankla </span>
            <span text2> Mustakivi tee 17, 13912 Tallinn </span>
          </icon-value-field>
        </div>
      </div>

      <div class="card !flex-none p-6x">
        <div class="flex flex-col gap-5x">
          <h4>{{'common.label.contact_person' | translate}}</h4>

          <icon-value-field icon="monochromeUser">
            <span text1> Mari Kask </span>
            <span text2>
              <a href="tel:+3725854222" text2> +372 585 4222 </a>
              <a
                href="mailto:martin@alis.ee"
                class="font-text text-sm font-medium"
              >
                martin&#64;alis.ee
              </a>
            </span>
          </icon-value-field>
        </div>
      </div>

      <div class="card !flex-none p-6x">
        <div class="flex w-full flex-col gap-5x">
          <h4>{{'common.label.order_total' | translate}}</h4>
          <div class="flex flex-col gap-2x border-b border-grey-40 pb-5x">
            <div class="flex justify-between">
              <span class="font-text text-sm font-normal text-grey-100"
                >{{'common.label.without_vat' | translate}}</span
              >
              <span class="font-display text-sm font-bold text-grey-100"
                >{{6.430 | priceValue}}</span
              >
            </div>
            <div class="flex justify-between">
              <span class="font-text text-sm font-normal text-grey-100"
                >{{'common.label.vat' | translate}}</span
              >
              <span class="font-display text-sm font-bold text-grey-100"
                >{{1.820 | priceValue}}</span
              >
            </div>
          </div>
          <div class="flex justify-between">
            <span class="font-text text-sm font-normal text-grey-100"
              >{{'common.label.total' | translate}}
            </span>
            <span class="font-display text-base font-bold text-grey-100"
              >{{8.250 | priceValue}}
            </span>
          </div>
        </div>
      </div>
    </div>

    @for(item of items; track $index) {
    <div class="card gap-6x">
      <div class="flex flex-col gap-2x">
        <span class="font-text text-xs text-grey-100">
          {{'common.label.order' | translate}}
          <span class="font-display font-medium">PO-BRIGH-2408000002</span>
        </span>
        <h5>
          <span class="text-rustyOrange"> {{item.supplier}} </span>
          <span>&nbsp;{{'common.suffix.products' | translate}}</span>
          <span>&nbsp;({{item.products.length}})</span>
        </h5>
      </div>

      @for(product of data; track $index) {
      <order-product-card
        [data]="product"
        [isFinalized]="true"
      ></order-product-card>
      }
    </div>
    }

    <!-- 
    <cart-products-list-card
      *ngFor="let item of data.controls"
      class="h-max !flex-none"
      [formGroup]="formGroup"
      [data]="item"
      [isFinalized]="true"
    ></cart-products-list-card> -->
  </div>
</view-content>
