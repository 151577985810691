<nav
  class="nav-wrapper inherit flex h-14 flex-1 items-center justify-center gap-4x rounded-tl-3xl rounded-tr-3xl px-8x py-0"
>
  @let linkClasses =
    "group flex h-14 flex-1 flex-col items-center justify-center font-display gap-3x text-ellipsis whitespace-nowrap border-b-[0.188rem] border-b-[transparent] pt-2x [&.active]:border-b-tangerine cursor-pointer";

  @for (item of menuItems; track item.label) {
    <!-- LINK -->

    @if (item.function) {
      <a [class]="linkClasses" (click)="item.function()" matRipple>
        <ng-container
          *ngTemplateOutlet="linkContentTemplate; context: { item: item }"
          W
        ></ng-container>
      </a>
    } @else {
      <a
        [class]="linkClasses"
        [routerLink]="item.route"
        #rla="routerLinkActive"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="
          item.routerLinkActiveOptions ?? { exact: false }
        "
        matRipple
      >
        <ng-container
          *ngTemplateOutlet="
            linkContentTemplate;
            context: { item: item, isActive: rla.isActive }
          "
        ></ng-container>
      </a>
    }
  }
</nav>

<!-- LINK CONTENT TEMPLATE -->
<ng-template #linkContentTemplate let-item="item" let-isActive="isActive">
  <!-- LINK ICON -->

  <svg-icon
    [name]="isActive ? item.iconActive : item.icon"
    [svgStyle]="{ width: 'var(--9x)', height: 'var(--9x)' }"
    viewBox="auto"
  ></svg-icon>

  <!-- LINK LABEL -->
  <span
    class="text-center text-[0.625rem] font-medium tracking-wide text-grey-100 group-[&.active]:font-bold"
  >
    {{ item.label | translate }}
  </span>
</ng-template>

<!-- OVERLAY TEMPLATES -->
@let iconClasses = "flex size-15x items-center justify-center";

<!-- SEARCH TEMPLATES -->
<ng-template #searchHeaderTemplate>
  <!-- SEARCH ICON -->
  <div [class]="iconClasses">
    <svg-icon src="menuSearch" class="size-8-5x"></svg-icon>
  </div>
  <!-- SEARCH INPUT -->
  <input
    class="flex flex-1 font-display text-sm font-bold text-grey-100 outline-none"
    [placeholder]="'menu.navigator.search_placeholder' | translate"
    autofocus
  />
</ng-template>
<ng-template #searchContentTemplate> ...</ng-template>
<!--  -->

<!-- SHOW MORE TEMPLATE -->
<ng-template #showMoreContentTemplate>
  <div class="py-4x">
    <a
      ov-menu-item
      label="menu.navigator.locations"
      #locationsLink="routerLinkActive"
      [icon]="locationsLink.isActive ? 'menuLocationsActive' : 'menuLocations'"
      routerLink="/locations"
      routerLinkActive="active"
    ></a>
    <a
      ov-menu-item
      label="menu.navigator.saved_carts"
      #savedCarts="routerLinkActive"
      [icon]="savedCarts.isActive ? 'menuSavedCartsActive' : 'menuSavedCarts'"
      routerLink="/saved"
      routerLinkActive="active"
    ></a>
    <!-- <a
      ov-menu-item
      label="Lemmikud"
      icon="/assets/icons/menu/menu-favorites.svg"
      routerLink="/favorites"
      routerLinkActive="active"
    ></a> -->
    <a
      ov-menu-item
      label="menu.navigator.favorites"
      #favorites="routerLinkActive"
      [icon]="favorites.isActive ? 'menuFavoritesActive' : 'menuFavorites'"
      routerLink="/stylesheet"
      routerLinkActive="active"
    ></a>
  </div>
</ng-template>
<!--  -->
