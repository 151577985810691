<div class="flex flex-1 flex-col overflow-auto bg-appBg">
  <div id="app-container">
    <!-- HEADER -->
    <app-header></app-header>

    <!-- CONTENT OUTLET - Component should have class 'view' -->
    <router-outlet></router-outlet>
  </div>

  <!-- NAVIGATOR -->
  <app-navigator class="absolute bottom-0 left-0 right-0"></app-navigator>
</div>
