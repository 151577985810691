<!-- <view-content class="max-w-sm"> -->
<div class="flex flex-1 flex-col gap-8x">
  <div class="flex justify-center">
    <svg-icon
      class="size-[7.5rem]"
      name="eStore"
      [svgStyle]="{ 'width.rem': 7.5, 'height.rem': 7.5 }"
    ></svg-icon>
  </div>
  <div class="my-5x flex flex-col items-center justify-center text-center">
    <h2 class="text-center">Logi sisse</h2>
    <h2 class="text-center">Lexi.Market e-poodi</h2>
  </div>
  <mat-tab-group
    class="login-tab-group no-tab-header"
    animationDuration="0"
    [(selectedIndex)]="selectedLogin"
  >
    <mat-tab class="login-tab" label="Parool">
      <form
        class="mt-10x"
        [formGroup]="passwordForm"
        (ngSubmit)="loginPassword()"
        novalidate
      >
        <form-field label="Email">
          <app-input
            inputClass="login-input"
            autocomplete="email"
            type="email"
            name="email"
            for="username"
            placeholder="{{ 'login.placeholders.e_mail' | translate }}"
          >
            <validation-errors for="username"></validation-errors>
          </app-input>
        </form-field>

        <form-field label="Password">
          <app-input
            class="mt12"
            inputClass="login-input"
            autocomplete="password"
            type="password"
            for="password"
            placeholder="{{ 'login.placeholders.password' | translate }}"
          >
            <validation-errors for="password"></validation-errors>
          </app-input>
          <div class="flex min-w-full justify-end py-5x">
            <a class="link" (click)="toResetPassword()"
              >{{ "Unustasid parooli?" | translate }}</a
            >
          </div>
        </form-field>

        <button
          appButton
          class="min-w-full"
          type="primary"
          size="big"
          buttonType="submit"
          label="Log in"
          [loading]="loginLoading"
        ></button>
      </form>
    </mat-tab>
    <mat-tab class="login-tab" label="Mobiil-ID">
      <form
        class="mt-10x"
        [formGroup]="passwordForm"
        (ngSubmit)="loginMobileId()"
        novalidate
      >
        <form-field label="Telefoni number">
          <app-input
            inputClass="login-input"
            autocomplete="email"
            type="email"
            name="email"
            for="username"
            placeholder="{{ 'login.placeholders.e_mail' | translate }}"
          >
            <validation-errors for="username"></validation-errors>
          </app-input>
        </form-field>

        <button
          appButton
          class="min-w-full"
          color="primary"
          size="big"
          buttonType="submit"
          label="Log in"
          [loading]="loginLoading"
        ></button>
      </form>
    </mat-tab>
  </mat-tab-group>

  <div class="mt-8x flex items-end">
    <div class="flex flex-1 border-t border-t-grey-30 py-8x">
      <button
        appButton
        class="min-w-full"
        color="tertiary"
        size="big"
        label="Loo konto"
        routerLink="/register"
      ></button>
    </div>
  </div>
</div>
<!-- </view-content> -->
