import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { StoreProductsService } from 'src/app/services/store-products.service';

export const storeProductsListResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(StoreProductsService).getStoreProductsList();
};
