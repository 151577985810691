import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpInterceptorFn,
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  HttpBadRequestResponse,
  HttpNotFoundResponse,
  HttpUnauthorizedResponse,
} from './BadRequest';
import { routes } from 'src/app/app.routes';
import { AuthenticationService } from 'src/app/services/authentication.service';

export const errorInterceptor: HttpInterceptorFn = (request, next) => {
  const _authService = inject(AuthenticationService);
  const _router = inject(Router);

  return next(request).pipe(
    catchError((err) => {
      const errorMessage = err.error?.validationSummary;

      if (err.status === 401) {
        // auto logout if 401 response returned from api
        _authService.logout();
        navigateWithReturnUrl('/login', _router);
        return new Observable<never>();
      } else if (err.status == 403) {
        const res = new HttpUnauthorizedResponse();
        Object.assign(res, err.error);
        navigateWithReturnUrl('/login', _router);
        return throwError(() => res);
      } else if (err.status === 400) {
        const res = new HttpBadRequestResponse();
        Object.assign(res, err.error);
        return throwError(() => res);
      } else if (err.status === 404) {
        const res = new HttpNotFoundResponse();
        return throwError(() => res);
      }

      const error = err.message || err.statusText;
      return throwError(() => error);
    }),
  );
};

function navigateWithReturnUrl(url: string, router: Router) {
  const location = document.location.pathname;
  const returnUrl = location == url ? undefined : location;
  router.navigate([url], { queryParams: { returnUrl: returnUrl } });
}
