import { Component, Inject, PLATFORM_ID } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterEvent,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { NavigatorComponent } from './components/_layout/app-navigator/navigator.component';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SvgIconComponent, SvgIconRegistryService } from 'angular-svg-icon';
import { HeaderComponent } from './components/_layout/app-header/header.component';
import { AppState } from './state/app.state';
import { Subscription, filter } from 'rxjs';
import { OverlayViewService } from './components/_layout/overlay-view/overlay-view.service';
import icons from '../assets/icons/svg-icons';
@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    RouterOutlet,
    RouterLink,
    NavigatorComponent,
    CommonModule,
    SvgIconComponent,
    HeaderComponent,
  ],
})
export class AppComponent {
  title = 'lexi-market-responsive';

  private _navigationSubscription: Subscription;

  constructor(
    private _router: Router,
    private _state: AppState,
    @Inject(PLATFORM_ID)
    private platformId: Object,
    private _overlayViewService: OverlayViewService,
    private iconReg: SvgIconRegistryService,
  ) {
    Object.keys(icons).forEach((icon) => {
      this.iconReg.addSvg(icon, icons[icon]);
    });

    this.iconReg.addSvg('eng', icons['gbr']);

    this._navigationSubscription = this._router.events.subscribe((event) => {
      // console.log(event);

      if (event instanceof NavigationEnd) {
        this._overlayViewService.clear();
        this._state.isViewLoaded = true;
        this._state.isPageLoaded = true;
        if (isPlatformBrowser(this.platformId)) {
          window.scroll(0, 0);
        }

        const scrollTopContainer = document.getElementById('app-container');
        if (scrollTopContainer) {
          scrollTopContainer.scrollTop = 0;
        }
      }
    });
  }
}
