import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PAGE_SIZE } from 'src/app/config/pagination';
import { LOCAL_STORAGE_KEY } from '../config/local-storage-keys';
import { StoreState } from '../views/protected/e-store/store.state';

@Injectable({ providedIn: 'root' })
export class StoreProductsService {
  constructor(
    private _http: HttpClient,
    private _searchData: StoreState,
  ) {}

  public getStoreProductsList() {
    let searchData: any = this._searchData.serializeSearchData();
    return this._http.post(`api/products/store`, searchData, {
      params: {
        $top: `${PAGE_SIZE[LOCAL_STORAGE_KEY.PAGE_SIZE.PRODUCTS_LIST] || PAGE_SIZE.DEFAULT}`,
        $count: 'true',
      },
    });
  }
}
